import { get } from 'utils/api';

export const getProfileInfo = async (
  { organizationId, propertyId, portalUserId },
  options = {},
) => {
  const response = await get(
    `/portal/${organizationId}/${propertyId}/profile/${portalUserId}`,
    options,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const getUser = async ({
  organizationId,
  propertyId,
  id,
}: {
  organizationId: string,
  propertyId: string,
  id: string,
}) => {
  const response = await get(
    `/portal/${organizationId}/${propertyId}/user/${id}`,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const getApplicationInfo = async (
  { organizationId, propertyId, portalUserId },
  options = {},
) => {
  const response = await get(
    `/portal/${organizationId}/${propertyId}/application/${portalUserId}`,
    options,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const getHouseholdMemberStatus = async (
  { organizationId, propertyId, customerId, householdId },
  options = {},
) => {
  const baseUrl = `portal/${organizationId}/${propertyId}`;
  const householdUrl = `households/${householdId}`;
  const customerStatusUrl = `customers/${customerId}/status`;
  const response = await get(
    `/${baseUrl}/${householdUrl}/${customerStatusUrl}`,
    options,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};
