import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import moment from 'moment/moment';
import {
  assignUnitToProspect,
  getAvailableUnits,
  getUserUnits,
  isDesiredUnitAvailable,
  removeUnitFromProspect,
} from '../../services/unitsService';

export function useFetchAvailableUnits({
  organizationId,
  propertyId,
  formValues,
}) {
  const moveInDate = formValues?.moveInDate;
  const desiredBeds = formValues?.desiredBeds;
  const noMoveInDate = formValues?.moveInDate === 'showMeEverything';
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };

  const { desiredMoveInStartDate, desiredMoveInEndDate } =
    determineMoveInDates(moveInDate);

  const isFiltersPopulated =
    (desiredMoveInStartDate && desiredMoveInEndDate && !isNaN(desiredBeds)) ===
      true ||
    (noMoveInDate && !isNaN(desiredBeds));

  return useQuery(
    [formValues],
    () =>
      getAvailableUnits({
        organizationId,
        propertyId,
        desiredBeds,
        desiredMoveInStartDate,
        desiredMoveInEndDate,
        options,
      }),
    {
      enabled: isFiltersPopulated,
      staleTime: 60000,
    },
  );
}

export const useFetchPortalUsersUnits = ({
  organizationId,
  propertyId,
  portalUserId,
}) => {
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };

  return useQuery(
    ['portalUserUnits', portalUserId],
    () =>
      getUserUnits({
        organizationId,
        propertyId,
        portalUserId,
        options,
      }),
    {
      enabled: Boolean(portalUserId?.length),
      initialData: {
        desiredUnit: null,
        assignedUnit: null,
      },
    },
  );
};
export const useCheckForDesiredUnitAvailability = ({
  organizationId,
  propertyId,
  assignedUnitId,
}) => {
  return useQuery(
    ['useCheckForDesiredUnitAvailability', assignedUnitId],
    () => isDesiredUnitAvailable(organizationId, propertyId, assignedUnitId),
    {
      initialData: null,
      enabled: Boolean(assignedUnitId?.length),
    },
  );
};
export const useAddUnitToProspect = ({
  organizationId,
  propertyId,
  prospectId,
  onSuccessCallback,
  portalUserId,
}) => {
  const queryClient = useQueryClient();
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };
  return useMutation(
    ({ unitId }) =>
      assignUnitToProspect({
        organizationId,
        propertyId,
        prospectId,
        unitId,
        options,
      }),
    {
      onSuccess: (response) => {
        if (response?.id?.length) {
          if (onSuccessCallback) {
            onSuccessCallback();
          }
        }
        return queryClient.invalidateQueries(['portalUserUnits', portalUserId]);
      },
      onError: (error) => {
        return error;
      },
    },
  );
};

export function useRemoveUnitFromProspect({
  organizationId,
  propertyId,
  portalUserId,
}) {
  const queryClient = useQueryClient();
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };
  return useMutation(
    ({ prospectId, hasUnitBeenAssigned }) =>
      removeUnitFromProspect({
        organizationId,
        propertyId,
        prospectId,
        hasUnitBeenAssigned,
        options,
      }),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['portalUserUnits', portalUserId]);
      },
    },
  );
}

function determineMoveInDates(range) {
  let desiredMoveInStartDate = null;
  let desiredMoveInEndDate = null;
  const today = moment();
  switch (range) {
    case 'thirtyDays':
      desiredMoveInStartDate = moment(today).format('MM/DD/YYYY');
      desiredMoveInEndDate = moment(today).add(30, 'days').format('MM/DD/YYYY');
      break;
    case 'thirtyOneToSixtyDays':
      desiredMoveInStartDate = moment(today)
        .add(31, 'days')
        .format('MM/DD/YYYY');
      desiredMoveInEndDate = moment(today).add(60, 'days').format('MM/DD/YYYY');
      break;
    case 'sixtyOneToNinetyDays':
      desiredMoveInStartDate = moment(today)
        .add(61, 'day')
        .format('MM/DD/YYYY');
      desiredMoveInEndDate = moment(today).add(90, 'days').format('MM/DD/YYYY');
      break;
    default:
    case 'showMeEverything':
      break;
  }
  return { desiredMoveInStartDate, desiredMoveInEndDate };
}
