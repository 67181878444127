import { del, get, put } from '../utils/api';

export async function getAvailableUnits({
  organizationId,
  propertyId,
  desiredBeds,
  desiredMoveInStartDate,
  desiredMoveInEndDate,
  options,
}) {
  const params =
    desiredBeds && desiredMoveInEndDate && desiredMoveInEndDate
      ? new URLSearchParams({
          desiredBeds,
          desiredMoveInStartDate,
          desiredMoveInEndDate,
          limit: 15,
          sorted: true,
        })
      : null;

  const url = params
    ? `/public/${organizationId}/${propertyId}/availableUnits?${params}`
    : `/public/${organizationId}/${propertyId}/availableUnits`;

  const response = await get(url, options);
  if (response.error) {
    return response.error;
  }

  return response;
}

export async function assignUnitToProspect({
  organizationId,
  propertyId,
  prospectId,
  unitId,
  options,
}) {
  const response = await put(
    `/${organizationId}/${propertyId}/prospects/${prospectId}/unit/${unitId}`,
    {},
    options,
  );
  if (response.error) {
    return response.error;
  }

  return response;
}

export async function removeUnitFromProspect({
  organizationId,
  propertyId,
  prospectId,
  hasUnitBeenAssigned = false,
  options,
}) {
  const url = `/${organizationId}/${propertyId}/prospects/${prospectId}/unit${
    hasUnitBeenAssigned ? '?hasUnitBeenAssigned=true' : ''
  }`;

  const response = await del(url, options);
  if (response.error) {
    return response.error;
  }

  return response;
}

export async function isDesiredUnitAvailable(
  organizationId,
  propertyId,
  unitId,
) {
  if (!unitId) return null;
  const availableUnits = await getAvailableUnits({
    organizationId,
    propertyId,
  });

  return !!availableUnits.data.find((unit) => unit.unitId === unitId);
}

export const getUserUnits = async ({
  organizationId,
  propertyId,
  portalUserId,
  options,
}) => {
  let response = null;

  response = await get(
    `/portal/${organizationId}/${propertyId}/units/${portalUserId}`,
    options,
  );

  if (response.error) {
    response = response.error;
  }

  return response;
};
