import { v5 as uuidv5 } from 'uuid';

export const TITLE = 'My Unit';
const UUID_NAMESPACE = '022cfa5a-9c86-4e1a-8ed8-8d3435fd5bde';
export const NO_PREFERENCE_TEXT = 'No Preference';
export const NO_PREFERENCE_UUID = uuidv5(NO_PREFERENCE_TEXT, UUID_NAMESPACE);
export const BEDROOM_OPTIONS = [
  {
    value: '0',
    text: 'Studio',
  },
  {
    value: '1',
    text: '1 Bedroom',
  },
  {
    value: '2',
    text: '2 Bedrooms',
  },
  {
    value: '3',
    text: '3 Bedrooms',
  },
  {
    value: '4',
    text: '4 Bedrooms',
  },
  {
    value: '5',
    text: '5 Bedrooms',
  },
];
