import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from './AppContext';

export const ViewsContext = React.createContext();

export const ViewsContextProvider = ({ children }) => {
  const { features, propertySettings } = useContext(AppContext);

  const showMoveInPreferenceStandaloneView = useMemo(
    () =>
      Boolean(
        features.propertySettingApplyWithoutAUnitFlag &&
          (!features.assigningUnitsAtApplicationFlag ||
            (features.assigningUnitsAtApplicationFlag &&
              !propertySettings.isUnitSelectionEnabled)),
      ),
    [
      features.propertySettingApplyWithoutAUnitFlag,
      features.assigningUnitsAtApplicationFlag,
      propertySettings.isUnitSelectionEnabled,
    ],
  );

  const showUnitSelectionWithMoveInPreferenceView = useMemo(
    () =>
      Boolean(
        features.propertySettingApplyWithoutAUnitFlag &&
          features.assigningUnitsAtApplicationFlag &&
          propertySettings.isUnitSelectionEnabled &&
          propertySettings.isApplyWithoutUnitEnabled,
      ),
    [
      features.propertySettingApplyWithoutAUnitFlag,
      features.assigningUnitsAtApplicationFlag,
      propertySettings.isUnitSelectionEnabled,
      propertySettings.isApplyWithoutUnitEnabled,
    ],
  );

  const showHouseholdView = useMemo(
    () =>
      Boolean(
        !features.propertySettingApplyWithoutAUnitFlag &&
          (!features.assigningUnitsAtApplicationFlag ||
            (features.assigningUnitsAtApplicationFlag &&
              !propertySettings.isUnitSelectionEnabled)),
      ),
    [
      features.propertySettingApplyWithoutAUnitFlag,
      features.assigningUnitsAtApplicationFlag,
      propertySettings.isUnitSelectionEnabled,
    ],
  );

  const showUnitSelectionStandaloneView = useMemo(
    () =>
      Boolean(
        (!features.propertySettingApplyWithoutAUnitFlag &&
          features.assigningUnitsAtApplicationFlag &&
          propertySettings.isUnitSelectionEnabled) ||
          (features.propertySettingApplyWithoutAUnitFlag &&
            features.assigningUnitsAtApplicationFlag &&
            propertySettings.isUnitSelectionEnabled &&
            !propertySettings.isApplyWithoutUnitEnabled),
      ),
    [
      features.propertySettingApplyWithoutAUnitFlag,
      features.assigningUnitsAtApplicationFlag,
      propertySettings.isUnitSelectionEnabled,
      propertySettings.isApplyWithoutUnitEnabled,
    ],
  );

  const viewsContextState = {
    showHouseholdView,
    showUnitSelectionStandaloneView,
    showMoveInPreferenceStandaloneView,
    showUnitSelectionWithMoveInPreferenceView,
  };

  return (
    <ViewsContext.Provider value={viewsContextState}>
      {children}
    </ViewsContext.Provider>
  );
};

ViewsContextProvider.defaultProps = {
  children: null,
};
ViewsContextProvider.propTypes = {
  children: PropTypes.node,
};

export default {
  ViewsContextProvider,
  ViewsContext,
};
