import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ToastContainer } from 'react-toastify';

import theme from 'themes/global';

import 'css/react-datetime.css';
import 'css/et-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'css/react-toastify.css';

import { AppContextProvider } from 'contexts/AppContext';
import { DialogProvider } from 'contexts/DialogContext';
import Background from './Background';
import { getLocalAuth } from '../utils/auth';
import { ViewsContextProvider } from '../contexts/ViewsContext';

const Gateway = loadable(() => import('pages/Gateway'));
const NotFound = loadable(() => import('pages/NotFound'));
const VerifyToken = loadable(() => import('./VerifyToken'));
const UniversalFusionAuthLogin = loadable(() =>
  import('./UniversalFusionAuthLogin'),
);

const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    background-attachment: fixed;
    background-color: #EEEEEE;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #4A4A4A;
  }
`;

const authLink = setContext((_, { headers }) => {
  const { authToken } = getLocalAuth();
  const newHeaders = {
    ...headers,
    authorization: authToken ? `Bearer ${authToken}` : null,
  };
  return {
    headers: newHeaders,
  };
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_PAYMENTS_API_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <Router forceRefresh={false}>
            <GlobalStyles />
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AppContextProvider>
                <ViewsContextProvider>
                  <Route
                    render={({ location: { pathname } }) =>
                      !pathname.includes(['contact-us']) ? (
                        <Background />
                      ) : undefined
                    }
                  />
                  <Switch>
                    <Route
                      path="/:organizationId/:propertyId"
                      component={Gateway}
                    />
                    <Route exact path="/verifyToken" component={VerifyToken} />
                    <Route
                      exact
                      path="/login"
                      component={UniversalFusionAuthLogin}
                    />

                    <Route component={NotFound} />
                  </Switch>
                </ViewsContextProvider>
              </AppContextProvider>
            </QueryClientProvider>
            <ToastContainer theme="colored" pauseOnFocusLoss={false} />
          </Router>
        </DialogProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
