export const HOUSEHOLD_TYPE_CONVENTIONAL = 'Conventional';
export const HOUSEHOLD_TYPE_AFFORDABLE = 'Affordable';
export const HOUSEHOLD_TYPE_MIXED = 'Mixed';

export const LEASEHOLDER_NOTE =
  'Any adult over the age of 17 that will be a' +
  ' part of your household, including roommates, adult children, partners,' +
  ' spouses, caretakers and guarantors.';

export const MINOR_NOTE =
  'Any person under the age of 18 that will be a part' +
  ' of your household for more than 14 days a year.';

export const PET_NOTE =
  'Any living animal, reptile, rodent,' +
  ' fish, etc. that will be a part of your household.';

export const NOTES = {
  leaseholders: LEASEHOLDER_NOTE,
  minors: MINOR_NOTE,
  pets: PET_NOTE,
};

export const HOUSEHOLD_STATUS_NOT_STARTED = 'Not Started';
export const HOUSEHOLD_STATUS_NEW_PORTAL_APP = 'New Portal App';
export const HOUSEHOLD_STATUS_IN_PROCESS = 'In Process';
export const HOUSEHOLD_STATUS_APPROVED = 'Approved';
export const HOUSEHOLD_STATUS_CANCELED = 'Canceled';
export const HOUSEHOLD_STATUS_DENIED = 'Denied';

export const APPLICATION_STATUSES = {
  notStarted: 'Not Started',
  started: 'In Progress',
  complete: 'Completed',
};

export const INVITE_STATUSES = {
  notSent: 'Not Sent',
  sent: 'Sent',
  claimed: 'Claimed',
};

export const LEASEHOLDERS = 'leaseholders';
export const MINORS = 'minors';
export const PETS = 'pets';
export const VEHICLES = 'vehicles';
export const EMERGENCY_CONTACTS = 'emergencyContacts';