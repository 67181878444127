import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'fonts/fonts';
import { unregister } from 'utils/serviceWorker';
import TagManager from 'react-gtm-module';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getOrgIdAndPropertyIdFromURL } from 'utils';
import {
  initializeDatadogRum,
  startDatadogSessionReplayRecording,
  addFeatureFlagEvaluation,
} from 'utils/datadog';
import { IntlProvider } from 'react-intl';
import translations from 'locales';

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_TAG_MANAGER_ID}`,
};

TagManager.initialize(tagManagerArgs);
(async () => {
  // This also persists intent data to local storage
  const { propertyId, organizationId } = getOrgIdAndPropertyIdFromURL();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${process.env.REACT_APP_LAUNCHDARKLY_TOKEN}`,
    user: {
      key: 'portalUser',
      custom: { propertyId, organizationId },
    },
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  const language = navigator.language.split(/[-_]/)[0];

  ReactDOM.render(
    <LDProvider>
      <IntlProvider
        locale={language}
        defaultLocale="en"
        messages={translations[language] || translations.en}
      >
        <App />
      </IntlProvider>
    </LDProvider>,
    document.getElementById('root'),
  );

  unregister();
})();

initializeDatadogRum();
startDatadogSessionReplayRecording();
