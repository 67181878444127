import React, { useRef, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const DialogContext = React.createContext();

const StyledDialogProvider = styled(DialogContext.Provider)`
  position: relative;
  z-index: 0;
`;

/**
 * Creates a DialogProvider to provide three pertinent values wherever dialogs are used:
 * isOpen - State of the dialog. (Ex: `{isOpen && <Dialog> ... </Dialog>}`)
 * show - Callback to open the dialog.
 * hide - Callback to hide the dialog.
 */
export function DialogProvider({ children }) {
  const dialogRef = useRef();

  const [isOpen, setIsOpen] = useState(null);
  const hide = () => setIsOpen(null);
  const show = (target) => setIsOpen(target);

  const value = {
    isOpen,
    hide,
    show,
    dialogRef,
  };

  return (
    <>
      <StyledDialogProvider value={value}>{children}</StyledDialogProvider>
      <div ref={dialogRef} />
    </>
  );
}

/**
 * Returns a Portal to display a dialog if `isOpen` is true.
 */
export function DialogPortal({ children }) {
  const { dialogRef } = useContext(DialogContext);

  return dialogRef ? createPortal(children, dialogRef.current) : null;
}

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default { DialogProvider, DialogContext, DialogPortal };
