/**
 * Regexes for yup validation
 */
export const REGEX_CHECKED = /on/;
export const REGEX_YES_NO = /(yes|no)/;
export const REGEX_YES_NO_NOT_PROVIDE = /(yes|no|notWishToProvide)/;
export const REGEX_IMG_DATA_URL = /^data:image\/png;base64,/;
export const REGEX_DATE_DISPLAY = /^\d{2}-\d{2}-\d{4}$/; // Accepts MM-DD-YYYY
export const REGEX_DATE = /^\d{4}-\d{2}-\d{2}$/; // Accepts YYYY-MM-DD
export const REGEX_TIME = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/; // Accepts 00:00 and 23:59
export const REGEX_PHONE = /^[2-9]{1}\d{2}\s?-?\d{3}-?\d{4}$/; // Accepts 222-333-4444, 2223334444, 222 333-4444
export const REGEX_ZIP_CODE = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i; // Accepts all international zip codes
export const REGEX_NUMBER = /^[0-9]$/;
export const REGEX_SSN = /^\d{3}-?\d{2}-?\d{4}$/; // Accepts 111-22-3333, 111223333
export const REGEX_CONTAINS_LOWERCASE = /.*[a-z].*$/;
export const REGEX_CONTAINS_UPPERCASE = /.*[A-Z].*$/;
export const REGEX_CONTAINS_NUMBER = /.*[0-9].*$/;
export const REGEX_DUNS = /^(\d+-?)+\d+$/; // Accepts 123456789, or 1 dash anywhere in the string.
export const REGEX_FEID = /^\d{2}-?\d{7}$/; // Accepts 12-1234567, 123456789
export const REGEX_NAME = /^[a-zA-Z][a-zA-Z0-9 .'-]*$/; // Ensures the first character is alphabetical
export const REGEX_INTEGER = /^\d+$/;
