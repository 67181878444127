import React from 'react';
import { useAvailableUnitContext } from 'contexts/AvailableUnitContext.hooks';

export const AvailableUnitContext = React.createContext();

export const AvailableUnitContextProvider = ({
  children,
}: {
  children: React.ReactNode,
}) => {
  const availableUnitContextState = useAvailableUnitContext();

  return (
    <AvailableUnitContext.Provider value={availableUnitContextState}>
      {children}
    </AvailableUnitContext.Provider>
  );
};

export default { AvailableUnitContextProvider, AvailableUnitContext };
