import { API_URL, IS_DEV } from 'utils/constants';
import { destroyLocalAuth, getLocalAuth, setLocalAuth } from 'utils/auth';
import { post } from 'utils/api';
import { updateOrgIdAndPropertyIdFromURL } from 'utils';

export const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const authHostURL = process.env.REACT_APP_AUTH_HOST_URL;
export const tenantId = process.env.REACT_APP_AUTH_TENANT_ID;
const LOCAL_URL_DATA = 'LOCAL_URL_DATA';

export const getLocalURLData = (): {
  organizationId: string,
  propertyId: string,
} => {
  const jsonURLData =
    sessionStorage.getItem(LOCAL_URL_DATA) ||
    localStorage.getItem(LOCAL_URL_DATA) ||
    '{}';
  return JSON.parse(jsonURLData);
};
export const setLocalURLData = (urlData: {
  organizationId: string,
  propertyId: string,
}) => {
  if (urlData?.organizationId?.length && urlData?.propertyId?.length) {
    const jsonUrlData = JSON.stringify(urlData);
    localStorage.setItem(LOCAL_URL_DATA, jsonUrlData);
    sessionStorage.setItem(LOCAL_URL_DATA, jsonUrlData);
  }
};

export const verifyTokenAndLoginUser = async (): Promise<string> => {
  let organizationId;
  let propertyId;
  const params = new URLSearchParams(window.location.search);
  const state = JSON.parse(params.get('state')) || {};

  const url = `${API_URL}/portal/auth/verifyToken`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      code: params.get('code'),
      state: JSON.stringify(state),
      locale: params.get('locale'),
    }),
  };

  const response = await fetch(url, options);

  if (response.status === 200) {
    const json = await response.json();
    const { token, sanitizedUser } = json;

    const isAuthenticated = Boolean(
      token && sanitizedUser && json.organizationId && json.propertyId,
    );
    if (isAuthenticated) {
      organizationId = json.organizationId; // eslint-disable-line
      propertyId = json.propertyId; // eslint-disable-line

      setLocalAuth({
        token,
        sanitizedUser: { ...sanitizedUser, organizationId, propertyId },
      });

      setLocalURLData({
        organizationId: json.organizationId,
        propertyId: json.propertyId,
      });
    }
  } else {
    // Check localStorage for ids needed to construct the url
    const URLData = getLocalURLData();
    if (URLData?.organizationId && URLData?.propertyId) {
      organizationId = URLData.organizationId; // eslint-disable-line
      propertyId = URLData.propertyId; // eslint-disable-line
    }

    const json = await response.json();
    if (json.error) {
      if (IS_DEV) {
        console.error(json);
      }

      if (json.error !== 'Bad Request' && typeof json.error === 'string') {
        throw new Error(json.error);
      } else {
        // Force logout in case of infinite redirect on error
        return logoutFusionAuthUser(true);
      }
    }
  }

  return organizationId?.length && propertyId?.length
    ? `/${organizationId}/${propertyId}`
    : '/login';
};

export const redirectToFusionAuthLogin = ({
  organizationId,
  propertyId,
  heroImageURL,
  backgroundImageURL,
}: {
  organizationId?: string,
  propertyId?: string,
  heroImageURL?: string,
  backgroundImageURL?: string,
} = {}): null => {
  const redirectUri = `${window.location.origin}/verifyToken`;
  const formType = window.location.pathname.includes('/register')
    ? 'register'
    : 'authorize';

  if (organizationId && propertyId) {
    setLocalURLData({ organizationId, propertyId });
  }

  const stateObject = {
    origin_pathname: window.location.pathname,
    clientId,
    organizationId,
    propertyId,
    heroImageURL,
    backgroundImageURL,
    redirect_uri: redirectUri,
    // [rplc_email] is a key for replacing with the user's email in fusion auth templates
    emailAddress: 'rplc_email',
  };

  const params = new URLSearchParams({
    client_id: clientId,
    response_type: 'code',
    redirect_uri: redirectUri,
    state: JSON.stringify(stateObject),
  });

  const url = new URL(`${authHostURL}/oauth2/${formType}?${params}`);

  return window.location.assign(url);
};

export const logoutFusionAuthUser = (forceLogout: boolean = false): null => {
  let url;

  const portalLoginURL = `${window.location.origin}/login`;

  const localAuth = getLocalAuth();

  if (localAuth.authToken !== null || forceLogout) {
    destroyLocalAuth();

    const params = new URLSearchParams({
      client_id: clientId,
      tenantId,
      post_logout_redirect_uri: portalLoginURL,
    });

    url = new URL(`${authHostURL}/oauth2/logout?${params}`);
  } else {
    url = portalLoginURL;
  }

  updateOrgIdAndPropertyIdFromURL();

  return setTimeout(() => window.location.assign(url), 1000);
};

export const requestFusionAuthVerificationEmail = async (
  organizationId,
  propertyId,
  emailAddress,
) => {
  const data = {
    emailAddress,
  };

  const response = await post(
    `/portal/${organizationId}/${propertyId}/auth/requestVerifyEmail`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};
