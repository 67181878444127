/**
 * Series of Cookie Helpers loosely based on
 * https://www.quirksmode.org/js/cookies.html
 * https://www.w3schools.com/js/js_cookies.asp
 */

/**
 * Creates a Cookie
 * @param {string} name name of cookie
 * @param {string} value value of cookie
 * @param {number} seconds expiration of cookie (in seconds)
 * @returns {void}
 */
export const createCookie = (name, value, seconds) => {
  let expires = '';
  if (seconds) {
    expires = `; max-age=${seconds}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/; SameSite=Lax;`;
};

/**
 * Reads a Cookie
 * @param {string} name name of cookie to read
 * @returns {string|null} value of cookie
 *
 * Better algorithm for matching cookies:
 * https://stackoverflow.com/questions/10730362/get-cookie-by-name
 */
export const readCookie = (name) => {
  const cookieString = document.cookie;
  const match = cookieString.match(RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

/**
 * Erases (Clears) a Cookie
 * @param {string} name name of cookie to clear
 * @returns {void}
 */
export const eraseCookie = (name) => {
  createCookie(name, '', -1);
};
