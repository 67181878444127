import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'contexts/AppContext';
import { UserContext } from 'contexts/UserContext';
import {
  useAddUnitToProspect,
  useCheckForDesiredUnitAvailability,
  useFetchPortalUsersUnits,
  useRemoveUnitFromProspect,
} from 'pages/SelectUnit/hooks';
import { deleteLocalUnitIntentData } from 'utils';
import { toast } from 'react-toastify';
import { ProspectPreferenceContext } from 'contexts/ProspectPreferenceContext';

export const useAvailableUnitContext = () => {
  const [assignedUnit, setAssignedUnit] = useState(null);
  const [selectUnitIsLocked, setSelectUnitIsLocked] = useState(null);

  const {
    organizationId,
    propertyId,
    applicationFormComplete,
    features,
    propertySettings,
  } = useContext(AppContext);
  const {
    authUser,
    prospectId,
    areApplicationFeesPaid,
    userProfile: { isResident, isPriorResident },
  } = useContext(UserContext);
  const {
    actions: { updateProspectPreference },
  } = useContext(ProspectPreferenceContext);

  const portalUserId = authUser.id;
  const hasAssignedUnit = Boolean(assignedUnit?.unitId?.length);
  const assignedUnitId = hasAssignedUnit ? assignedUnit?.unitId : null;
  const disableUnitSelection = Boolean(
    selectUnitIsLocked || (areApplicationFeesPaid && applicationFormComplete),
  );

  const getUserUnits = useFetchPortalUsersUnits({
    organizationId,
    propertyId,
    portalUserId,
  });
  const userUnits = getUserUnits.data;

  const hasAssignedUnitResponse = Boolean(
    userUnits?.assignedUnit?.unitId?.length,
  );
  const hasDesiredUnitResponse = Boolean(
    userUnits?.desiredUnit?.unitId?.length,
  );
  const getUnitAvailability = useCheckForDesiredUnitAvailability({
    organizationId,
    propertyId,
    assignedUnitId: userUnits?.desiredUnit?.unitId,
  });
  const refetchIsUnitAvailable = getUnitAvailability.refetch;
  const isUnitAvailable = getUnitAvailability.data ?? null;
  const isUnitAvailableLoading =
    getUnitAvailability.isLoading || getUnitAvailability.isFetching;
  const removeUnitFromProspect = useRemoveUnitFromProspect({
    organizationId,
    propertyId,
    portalUserId,
  });

  const removeUnitFromProspectMutateAsync = removeUnitFromProspect.mutateAsync;
  const assignUnitToProspect = useAddUnitToProspect({
    organizationId,
    propertyId,
    prospectId,
    portalUserId,
    onSuccessCallback: deleteLocalUnitIntentData,
  });
  const assignUnitToProspectMutate = assignUnitToProspect.mutate;

  const actions = useMemo(() => {
    const assignUnit = async (unitId) => {
      assignUnitToProspectMutate({
        prospectId,
        unitId,
      });
    };

    const removeUnit = async (hasUnitBeenAssigned: boolean = false) => {
      // Set selected move in date to null
      await removeUnitFromProspectMutateAsync({
        prospectId,
        hasUnitBeenAssigned,
      });
      updateProspectPreference({ moveInDateScheduled: null }, true);
      toast.success('Unit was removed successfully');
    };

    return {
      assignUnit,
      removeUnit,
    };
  }, [
    assignUnitToProspectMutate,
    prospectId,
    removeUnitFromProspectMutateAsync,
    updateProspectPreference,
  ]);

  const isLoading =
    getUnitAvailability.isFetching ||
    assignUnitToProspect.isLoading ||
    removeUnitFromProspect.isLoading ||
    (!getUserUnits.isFetchedAfterMount && getUserUnits.isFetching);

  // Sets Unit from [getUserUnits] to state
  // Manages [selectUnitIsLocked] state
  useEffect(() => {
    let unitValue = null;
    let shouldLockUnitSelection = false;

    // Always favor [assignedUnit] over [desiredUnit]
    switch (true) {
      case hasAssignedUnitResponse:
        unitValue = userUnits?.assignedUnit;
        shouldLockUnitSelection = true;
        break;

      case !hasAssignedUnitResponse && areApplicationFeesPaid:
        unitValue = null;
        shouldLockUnitSelection = true;
        if (hasDesiredUnitResponse) {
          actions.removeUnit();
        }
        break;

      case hasDesiredUnitResponse && !areApplicationFeesPaid:
        unitValue = userUnits?.desiredUnit;
        break;

      default:
    }

    unitValue = unitValue ? { ...unitValue, id: unitValue.unitId } : null;
    setAssignedUnit(unitValue);
    setSelectUnitIsLocked(shouldLockUnitSelection);
  }, [
    actions,
    areApplicationFeesPaid,
    userUnits,
    hasAssignedUnitResponse,
    hasDesiredUnitResponse,
  ]);

  // Show toast if [desiredUnit] is no longer available
  const shouldShowUnavailableUnitToast =
    features.isAssigningUnitsAtApplicationEnabled &&
    !propertySettings.isApplyWithoutUnitEnabled &&
    isUnitAvailable === false &&
    !isResident &&
    !isPriorResident &&
    !hasAssignedUnitResponse;

  useEffect(() => {
    if (shouldShowUnavailableUnitToast) {
      toast.error(
        'The selected unit is no longer available. Select a new unit before you can continue.',
      );
      actions.removeUnit(true);
    }
  }, [actions, shouldShowUnavailableUnitToast]);

  return {
    actions,
    assignedUnit,
    assignedUnitId,
    hasAssignedUnit,
    setAssignedUnit,
    disableUnitSelection,
    hasAssignedUnitResponse,
    hasDesiredUnitResponse,
    isUnitAvailable,
    isLoading,
    refetchIsUnitAvailable,
    isUnitAvailableLoading,
  };
};
