import React, { useContext, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from 'contexts/AppContext';
import useIsAuthenticated from 'hooks/useIsAuthenticated';

const StyledAppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
  opacity: 0;
  filter: blur(5px);
  transition: opacity 225ms cubic-bezier(0.4, 0, 1, 1);
`;

const Background = () => {
  const ref = useRef(null);
  const appContext = useContext(AppContext);

  const marketingImage = appContext?.marketingInfo?.propertyPhotoLink1;
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    let preloaderImage = document.createElement('img');
    preloaderImage.addEventListener('load', () => {
      if (ref.current) {
        ref.current.style.backgroundImage = `url("${marketingImage}")`;
        ref.current.style.opacity = 1;
      }
      preloaderImage = null;
    });
    preloaderImage.src = marketingImage;
  }, [marketingImage]);

  useEffect(() => {
    if (ref.current && isAuthenticated) {
      ref.current.style.opacity = 0;
    }
  }, [isAuthenticated]);

  return <StyledAppContainer ref={ref} />;
};

export default Background;
