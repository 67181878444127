import React, { useContext, useMemo } from 'react';
import {
  useFetchProspectPreference,
  useUpdateProspectPreference,
} from './ProspectPreferenceContext.hooks';
import { toast } from 'react-toastify';
import { ReactNode } from 'react';
import { AppContext } from 'contexts/AppContext';
import { UserContext } from 'contexts/UserContext';
import { isNull } from 'lodash';

type ProspectPreferences = {
  hasUserChosenToProvideMoveInPreferences?: boolean,
  moveInDateFrom?: string,
  moveInDateScheduled?: string,
  moveInDateTo?: string,
  nBaths?: number,
  nBeds?: number,
  nBedsArr?: number[],
  nHalfBaths?: number,
  preferredFloorPlanId?: string,
  priceFrom?: number,
  priceTo?: number,
  prospectId?: string,
  unitId?: string,
};
type Actions = {
  updateProspectPreference: Promise<
    (partialProspectPreference: ProspectPreferences) => void,
  >,
};
type Value = {
  actions: Actions,
  prospectPreferences: ProspectPreferences,
};
const DEFAULT_VALUE = {};

const hasKey = (object: Object, key: string): boolean =>
  Object.keys(object).includes(key);

const handleToast = (partialProspectPreference: ProspectPreferences) => {
  let toastMessage = '';

  const unitMoveInDateScheduledUpdated = hasKey(
    partialProspectPreference,
    'moveInDateScheduled',
  );

  const moveInPreferencesUpdated = hasKey(
    partialProspectPreference,
    'preferredFloorPlanId',
  );

  if (unitMoveInDateScheduledUpdated) {
    const isRemoved = partialProspectPreference.moveInDateScheduled === null;
    toastMessage = `Move-In Date ${
      isRemoved ? 'removed successfully' : 'updated'
    }`;
  } else if (moveInPreferencesUpdated) {
    toastMessage = 'Move-In preferences updated';
  }

  if (toastMessage?.length) {
    toast.success(toastMessage);
  }
};

export const ProspectPreferenceContext = React.createContext(DEFAULT_VALUE);

export const ProspectPreferenceContextProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const { organizationId, propertyId } = useContext(AppContext);
  const { prospectId } = useContext(UserContext);

  const { mutateAsync } = useUpdateProspectPreference({
    organizationId,
    propertyId,
    prospectId,
  });

  const { data, isLoading } = useFetchProspectPreference({
    organizationId,
    propertyId,
    prospectId,
  });
  const prospectPreferences: ProspectPreferences = data;

  const hasUserChosenToProvideMoveInPreferences =
    prospectPreferences?.hasUserChosenToProvideMoveInPreferences ?? false;
  const moveInDateScheduled = prospectPreferences?.moveInDateScheduled ?? null;

  const hasMoveInPreferencesResponse = useMemo(() => {
    return Boolean(
      prospectPreferences?.moveInDateFrom &&
        prospectPreferences?.moveInDateTo &&
        (prospectPreferences?.nBedsArr ||
          isNull(prospectPreferences?.nBedsArr)) &&
        prospectPreferences?.preferredFloorPlanId,
    );
  }, [prospectPreferences]);

  const actions: Actions = useMemo(() => {
    const updateProspectPreference = async (
      partialProspectPreference: ProspectPreferences,
      disableToast: boolean = false,
    ) => {
      await mutateAsync(partialProspectPreference);

      if (!disableToast) {
        handleToast(partialProspectPreference);
      }
    };

    return {
      updateProspectPreference,
    };
  }, [mutateAsync]);

  const value: Value = {
    ...DEFAULT_VALUE,
    actions,
    prospectPreferences,
    hasMoveInPreferencesResponse,
    hasUserChosenToProvideMoveInPreferences,
    moveInDateScheduled,
    isLoading,
  };
  return (
    <ProspectPreferenceContext.Provider value={value}>
      {children}
    </ProspectPreferenceContext.Provider>
  );
};

export default { ProspectPreferenceContextProvider, ProspectPreferenceContext };
