import React, { createContext, useContext, useEffect } from 'react';
import { ReactNode } from 'react';
import {
  deleteLocalTellMeIntentData,
  deleteLocalUnitIntentData,
  getLocalTellMeIntentData,
  getLocalUnitIntentData,
} from 'utils';
import { ProspectPreferenceContext } from 'contexts/ProspectPreferenceContext';
import { AppContext } from 'contexts/AppContext';
import { AvailableUnitContext } from 'contexts/AvailableUnitContext';

type Value = {};

const DEFAULT_VALUE = {};

export const INTENT_TYPES = {
  ASSIGN_UNIT: 'assignUnit',
  TELL_US: 'tellUs',
};

export const IntentContext = createContext(DEFAULT_VALUE);

export const IntentContextProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const { organizationId, propertyId } = useContext(AppContext);
  const {
    actions: { updateProspectPreference },
  } = useContext(ProspectPreferenceContext);
  const {
    actions: { assignUnit },
    assignedUnitId,
  } = useContext(AvailableUnitContext);

  // handles [tellUs] intent from [embed]
  useEffect(() => {
    const { state: intentState } = getLocalTellMeIntentData();
    const isTellUsIntent = Boolean(intentState?.type === INTENT_TYPES.TELL_US);

    if (isTellUsIntent) {
      const handleTellUsIntent = async () => {
        await updateProspectPreference({
          hasUserChosenToProvideMoveInPreferences: true,
        });
        deleteLocalTellMeIntentData();
      };

      handleTellUsIntent();
    }
  }, [updateProspectPreference]);

  // handles [assignUnit] intent from [embed]
  useEffect(() => {
    const { state: intentState } = getLocalUnitIntentData();
    const isAssignUnitIntent = Boolean(
      intentState?.unitId?.length && intentState?.type === 'assignUnit',
    );

    if (isAssignUnitIntent) {
      const isForThisProperty = Boolean(
        intentState?.propertyId?.length &&
          intentState?.propertyId === propertyId,
      );

      if (!isForThisProperty) {
        return deleteLocalUnitIntentData();
      }

      const isNotAlreadyAssignedToThisUnit =
        assignedUnitId !== intentState.unitId;

      const shouldAssignNewUnit = Boolean(
        isForThisProperty &&
          (!assignedUnitId?.length || isNotAlreadyAssignedToThisUnit),
      );

      if (shouldAssignNewUnit) {
        const handleAssignUnitIntent = async () => {
          await assignUnit(intentState.unitId);
        };
        handleAssignUnitIntent();
      }
    }
  }, [assignUnit, assignedUnitId, organizationId, propertyId]);

  const value: Value = {
    ...DEFAULT_VALUE,
  };
  return (
    <IntentContext.Provider value={value}>{children}</IntentContext.Provider>
  );
};
