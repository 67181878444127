import jwtDecode from 'jwt-decode';

export const LOCALSTORAGE_AUTH_TOKEN = '__fortress__portal__authToken__';
export const LOCALSTORAGE_AUTH_USER = '__fortress__portal__authUser__';
export const TOKEN_REFRESH_AGE = 5 * 60; // 5 minutes

/**
 * Takes the `response` from a successful call to `authenticateUser()`
 * and sets up the `localStorage` keys.
 * @param {Object} response, typically from `authenticateUser()`
 * @returns {Boolean}
 */
export const setLocalAuth = ({ token, sanitizedUser }) => {
  if (token) {
    localStorage.setItem(LOCALSTORAGE_AUTH_TOKEN, token);
    sessionStorage.setItem(LOCALSTORAGE_AUTH_TOKEN, token);
  }
  if (sanitizedUser) {
    localStorage.setItem(LOCALSTORAGE_AUTH_USER, JSON.stringify(sanitizedUser));
    sessionStorage.setItem(
      LOCALSTORAGE_AUTH_USER,
      JSON.stringify(sanitizedUser),
    );
  }
  return true;
};

export const updateLocalAuthUser = (sanitizedUser) => {
  if (sanitizedUser) {
    const { authUser } = getLocalAuth();
    localStorage.setItem(
      LOCALSTORAGE_AUTH_USER,
      JSON.stringify({ ...authUser, ...sanitizedUser }),
    );
    sessionStorage.setItem(
      LOCALSTORAGE_AUTH_USER,
      JSON.stringify({ ...authUser, ...sanitizedUser }),
    );
  }
  return true;
};

/**
 * Queries `localStorage` and returns `authToken` and (parsed) `authUser`.
 * @returns {Object} `authToken`, `authUser`
 */
export const getLocalAuth = () => {
  const authToken =
    sessionStorage.getItem(LOCALSTORAGE_AUTH_TOKEN) ||
    localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN);
  const encodedAuthUser =
    sessionStorage.getItem(LOCALSTORAGE_AUTH_USER) ||
    localStorage.getItem(LOCALSTORAGE_AUTH_USER);

  let authUser = null;
  try {
    authUser = JSON.parse(encodedAuthUser);
  } catch {
    authUser = null;
  }

  return {
    authToken,
    authUser,
  };
};

/**
 * Destroys `localStorage` keys for `authToken` and `authUser`.
 * @returns {Boolean}
 */
export const destroyLocalAuth = () => {
  localStorage.removeItem(LOCALSTORAGE_AUTH_TOKEN);
  sessionStorage.removeItem(LOCALSTORAGE_AUTH_TOKEN);
  localStorage.removeItem(LOCALSTORAGE_AUTH_USER);
  sessionStorage.removeItem(LOCALSTORAGE_AUTH_USER);
  return true;
};

/**
 * Given a JWT token, determine if it is expired or not.
 * __WARNING: Does not _verify_ the token, only checks if expired.__
 * @param {string} token, a valid JWT token
 * @returns {Boolean}
 */
export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    return Date.now() >= decoded.exp * 1000;
  } catch {
    // Assume any invalid token is expired.
    return true;
  }
};

/**
 * Given a JWT token, determine if is _nearing_ expiration.
 * @param {string} token, a valid JWT token
 * @return {Boolean}
 */
export const isTokenOld = (token) => {
  try {
    const decoded = jwtDecode(token);
    return Date.now() >= (decoded.exp - TOKEN_REFRESH_AGE) * 1000;
  } catch {
    // Assume any invalid token is old.
    return true;
  }
};
