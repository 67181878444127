import { useContext, useMemo } from 'react';
import { getLocalAuth } from 'utils/auth';
import { AppContext } from 'contexts/AppContext';

const useIsAuthenticated = () => {
  const { organizationId, propertyId } = useContext(AppContext);
  const { authToken, authUser } = getLocalAuth();
  return useMemo(
    () =>
      authToken &&
      authUser?.id &&
      propertyId?.localeCompare(authUser.propertyId, 'en', {
        sensitivity: 'base',
      }) === 0 &&
      organizationId?.localeCompare(authUser.organizationId, 'en', {
        sensitivity: 'base',
      }) === 0,
    [authToken, authUser, organizationId, propertyId],
  );
};

export default useIsAuthenticated;
