import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getProspectPreference,
  updateProspectPreference,
} from 'services/prospectPreferenceService';

export const useFetchProspectPreference = ({
  organizationId,
  propertyId,
  prospectId,
}) => {
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };
  return useQuery(
    ['prospectPreferences', prospectId],
    () =>
      getProspectPreference({
        organizationId,
        propertyId,
        prospectId,
        options,
      }),
    {
      staleTime: 60000,
      enabled: Boolean(organizationId && prospectId && prospectId),
    },
  );
};

export const useUpdateProspectPreference = ({
  organizationId,
  propertyId,
  prospectId,
  portalUserId,
}) => {
  const queryClient = useQueryClient();
  const source = axios.CancelToken.source();
  const { token } = source;
  const options = { cancelToken: token };
  return useMutation(
    (partialProspectPreference) =>
      updateProspectPreference({
        organizationId,
        propertyId,
        prospectId,
        partialProspectPreference,
        options,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['portalUserUnits', portalUserId]);
        return queryClient.invalidateQueries([
          'prospectPreferences',
          prospectId,
        ]);
      },
      onError: (error) => {
        return error;
      },
    },
  );
};
