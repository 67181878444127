import React, { useContext, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { AppContext } from 'contexts/AppContext';
import { createCookie, readCookie } from 'utils/cookie';
import { requestVerificationEmail } from 'services/authService';
import { requestFusionAuthVerificationEmail } from 'services/fusionAuthService';
import { UserContext } from 'contexts/UserContext';

const AppBanner = loadable(() => import('components/Molecules/AppBanner'));

const COOKIE_IS_VERIFIED = '__fortress__portal__bannerIsVerified__';
export const COOKIE_VERIFICATION_EMAIL_REQUESTED =
  '__fortress__portal__verificationEmailRequested__';

const IN_30_MINUTES = 30 * 60;

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const BannerIsVerified = () => {
  const { organizationId, propertyId, authenticationPortal } =
    useContext(AppContext);
  const {
    authUser: { customer, prospect, verifyToken, isVerified, ...authUserRest },
    updateAuthUser,
  } = useContext(UserContext);

  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(null);
  const emailAddress =
    authUserRest?.emailAddress ??
    customer?.emailAddress ??
    prospect?.emailAddress;
  const isClosed = readCookie(COOKIE_IS_VERIFIED);

  const handleClose = () => {
    createCookie(COOKIE_IS_VERIFIED, 'true', IN_30_MINUTES);
    setShow(false);
  };

  const handleResend = useCallback(async () => {
    let response;

    if (authenticationPortal) {
      setStatus('sending');
      response = await requestFusionAuthVerificationEmail(
        organizationId,
        propertyId,
        emailAddress,
      );

      createCookie(COOKIE_VERIFICATION_EMAIL_REQUESTED, 'true', IN_30_MINUTES);
    } else {
      response = await requestVerificationEmail(
        organizationId,
        propertyId,
        emailAddress,
      );
    }

    if (response.error) {
      if (response.error === 'User already verified.') {
        setStatus('alreadyVerified');
        setTimeout(() => {
          updateAuthUser({ isVerified: true });
          setStatus('success');
        }, 3000);
      } else {
        setStatus('error');
      }
      return;
    }

    setStatus('success');
  }, [
    organizationId,
    propertyId,
    emailAddress,
    authenticationPortal,
    updateAuthUser,
  ]);

  const hideBanner: boolean = isClosed || isVerified || !show;

  const resolvedProps = useMemo(() => {
    const message = {
      [null]: 'Please verify your email.',
      sending: 'Sending verification email...',
      success: 'Verification Email Sent!',
      error: 'Unable to send Verification Email; please try again later.',
      alreadyVerified: 'Email has been verified.',
    }[status];

    const banStyle = {
      [null]: 'alert',
      sending: 'alert',
      success: 'success',
      error: 'danger',
    }[status];

    return {
      banStyle,
      onClose: authenticationPortal ? undefined : handleClose,
      children: (
        <>
          {message}
          &nbsp;
          {status === null || status === 'success' ? (
            <StyledLink onClick={handleResend}>
              {verifyToken || (authenticationPortal && status === 'success')
                ? 'Resend verification?'
                : 'Send verification?'}
            </StyledLink>
          ) : null}
        </>
      ),
    };
  }, [authenticationPortal, handleResend, status, verifyToken]);

  return hideBanner ? null : <AppBanner {...resolvedProps} />;
};

export default BannerIsVerified;
