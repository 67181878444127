import { portalButton } from '@fortress-technology-solutions/fortress-component-library/design';

const media = {
  mobile_s: '320px',
  mobile_m: '375px',
  mobile_l: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptop_l: '1440px',
  desktop: '2560px',
};

const sizing = {
  borderRadius: '4px',
  contentPadding: '20px',
  defaultMeasure: '15px',
  fontSize: '14px',
  fontSizeLarge: '18px',
  formFontSize: '14px',
  lineHeight: '18px',
  formHeight: '40px',
  frameMaxWidth: '414px',
  frameMinWidth: '0',
  cardPadding: '20px',
  appHeaderHeight: '100px',
  letterSpacing: '-0.547273px',
};

export const colors = {
  backgroundColor: '#ffffff',
  backgroundColorSub: '#faf9f9',
  backgroundDisabled: '#F3F3F3',
  backgroundStripe: '#FBFBFB',
  borderColor: '#dadada',
  backgroundColorDark: '#333232',
  backgroundColorDarkActive: '#2B2A2A',

  buttonOpacitySteps: [1.0, 0.8, 0.6, 0.2] /* [Up, Hover, Active, Disabled] */,
  buttonPrimaryBack: portalButton.primary.default.backgroundColor,
  buttonPrimaryFont: '#ffffff',
  buttonSecondaryBack: '#e2e2e2',
  buttonSecondaryFont: '#2a2a2a',

  circleActiveColor: '#A4C24A',
  circleDisabledColor: '#DFDFDF',
  circleErrorColor: '#DD4C44',

  hero: '#E3702D',
  fontColor: '#959595',
  headerColor: '#666666',
  iconColor: '#808080',
  labelColor: '#4D4D4D',
  spinnerColor: '#666666',
  paragraph: '#838383',
  linkColor: '#666666',
  helpMessage: '#999999',
  errorMessage: '#D83329',
  boxBorderColor: '#DFDFDF',

  success: '#A3C440',
  successBackground: '#F7FAEF',
  warning: '#E3702D',
  warningBackground: '#FBEBE1',
  danger: '#D83329',
  dangerBackground: '#F7D7D6',

  lineColor: '#6A6969',

  appBlack: '#000000',
  appWhite: '#FFFFFF',

  fontTitles: '#000000',
  fontContent: '#4A4A4A',
  fontPlaceholder: '#838383',
  fontHighlighted: '#F5F5F5',

  approved: '#A3C440',
  inProcess: '#CAA661',
  alert: '#F0A524',

  positive: '#D83329',
  negative: '#A3C440',

  disabled: '#AAAAAA',
  disabledInput: '#EDEDED',
};

export default {
  media,
  sizing,
  colors,
};
