import { FormattedMessage } from 'react-intl';

export const API_URL = process.env.REACT_APP_API_URL;
export const IS_DEV = process.env.REACT_APP_NODE_ENV === 'development';

/**
 * Commonly used dropdown values
 */
export const YES_NO_OPTIONS = [
  {
    label: <FormattedMessage id="app.yes" />,
    value: 'yes',
  },
  {
    label: <FormattedMessage id="app.no" />,
    value: 'no',
  },
];

export const YES_NO_NOTFURNISHED_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Do not wish to provide', value: 'notWishToFurnish' },
];

export const YES_NO_NOTPROVIDE_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Do not wish to provide', value: 'notWishToProvide' },
];

export const MARITAL_STATUSES_CONVENTIONAL = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
];

export const MARITAL_STATUSES_AFFORDABLE = [
  ...MARITAL_STATUSES_CONVENTIONAL,
  { label: 'Divorced', value: 'divorced' },
  { label: 'Separated', value: 'separated' },
  { label: 'Widowed', value: 'widowed' },
];

export const JOINT_CUSTODY_OPTIONS = [
  { label: 'No', value: 'no' },
  { label: 'Yes - 50% or more', value: 'yes50' },
  { label: 'Yes - less than 50%', value: 'yesLessThan50' },
];

export const BEDROOM_SIZE_OPTIONS = [
  { value: '0', text: 'Studio' },
  { value: '1', text: '1 Bedroom' },
  { value: '2', text: '2 Bedrooms' },
  { value: '3', text: '3 Bedrooms' },
  { value: '4', text: '4 Bedrooms' },
  { value: '5', text: '5 Bedrooms' },
];

export const MOVE_IN_DATE_OPTIONS = [
  { value: 'thirtyDays', text: 'Within 30 Days' },
  {
    value: 'thirtyOneToSixtyDays',
    text: '31-60 Days From Now',
  },
  {
    value: 'sixtyOneToNinetyDays',
    text: '61-90 Days From Now',
  },
  { value: 'showMeEverything', text: 'Show Me Everything' },
];

export const ZERO_TO_TEN_OPTIONS = Array.from({ length: 11 }, (v, i) => ({
  label: i,
  value: i,
}));

/**
 * Assets
 */
export const EL_LOGO_URL =
  'https://production.elmington.io/static/media/epm-logo-full.71d452cd.svg';

export const PRIVACY_POLICY_URL =
  'http://fortress-privacy-policy.s3-website-us-east-1.amazonaws.com/';

/**
 * Form Types
 */
export const APPLICATION_CONVENTIONAL = 'conventional';
export const APPLICATION_PRIMARY_AFFORDABLE = 'primaryAffordable';
export const APPLICATION_PRIMARY_AFFORDABLE_WITH_GLOBAL_DISABILITY =
  'primaryAffordableWithGlobalDisability';
export const APPLICATION_SECONDARY_AFFORDABLE = 'secondaryAffordable';
export const APPLICATION_SECONDARY_AFFORDABLE_WITH_GLOBAL_DISABILITY =
  'secondaryAffordableWithGlobalDisability';
export const APPLICATION_CONVENTIONAL_WA = 'conventionalWA';
export const APPLICATION_PRIMARY_AFFORDABLE_WA = 'primaryAffordableWA';
export const APPLICATION_SECONDARY_AFFORDABLE_WA = 'secondaryAffordableWA';
export const APPLICATION_PRIMARY_RECERT = 'primaryRecert';
export const APPLICATION_SECONDARY_RECERT = 'secondaryRecert';

export const FORM_TYPE_INCOME = 'income';
export const FORM_TYPE_ASSETS = 'assets';

export const FORM_MODES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const DATE_KEYS = [
  'moveInDateScheduled',
  'unitMoveInEndDate',
  'unitMoveInDate',
];
