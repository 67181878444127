import { getLocalUnitIntentData, numberParser } from 'utils';
import { post } from 'utils/api';
import { setLocalAuth } from 'utils/auth';
import { setUserMetadata } from 'utils/datadog';

export const authenticateUser = async (organizationId, propertyId, values) => {
  const data = {
    emailAddress: values.emailAddress,
    password: values.password,
  };

  const response = await post(
    `/portal/${organizationId}/${propertyId}/auth/login`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  setUserMetadata(response);
  setLocalAuth(response);
  return response;
};

export const registerUser = async (organizationId, propertyId, values) => {
  const intent = getLocalUnitIntentData();

  const data = {
    firstName: values.firstName,
    lastName: values.lastName,
    emailAddress: values.emailAddress,
    password: values.password,
    referralTypeId: values.referralTypeId,
    unitId: intent?.state?.unitId,
  };

  if (values.phoneNumber) {
    data.phoneNumber = numberParser(values.phoneNumber);
  }
  if (values.moveInDateFrom) {
    data.moveInDateFrom = values.moveInDateFrom;
  }
  if (values.moveInDateTo) {
    data.moveInDateTo = values.moveInDateTo;
  }
  if (values.numberOfBeds) {
    data.numberOfBeds = values.numberOfBeds;
  }
  if (values.nBedsArr?.length) {
    data.nBedsArr = values.nBedsArr;
  }

  const response = await post(
    `/portal/${organizationId}/${propertyId}/auth/register`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  setUserMetadata(response);
  setLocalAuth(response);
  return response;
};

export const requestResetPassword = async (
  organizationId,
  propertyId,
  { emailAddress },
) => {
  const data = {
    emailAddress,
  };

  const response = await post(
    `/portal/${organizationId}/${propertyId}/auth/resetPasswordToken`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const resetPassword = async (resetToken, { password }) => {
  const data = {
    token: resetToken,
    password,
  };

  const response = await post('/portal/auth/resetPassword', data);

  if (response.error) {
    return response.error;
  }

  return response;
};

export const requestVerificationEmail = async (
  organizationId,
  propertyId,
  emailAddress,
) => {
  const data = {
    emailAddress,
  };

  const response = await post(
    `/portal/${organizationId}/${propertyId}/auth/verifyToken`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const verifyEmail = async (verifyToken) => {
  const data = {
    token: verifyToken,
  };

  const response = await post('/portal/auth/verify', data);

  if (response.error) {
    return response.error;
  }

  return response;
};

export const checkClaimToken = async (claimToken, options = {}) => {
  const data = {
    token: claimToken,
  };

  const response = await post('/portal/auth/checkClaimToken', data, options);

  if (response.error) {
    return response.error;
  }

  return response;
};

export const claimAccount = async (claimToken, { password }) => {
  const data = {
    token: claimToken,
    password,
  };

  const response = await post('/portal/auth/claim', data);

  if (response.error) {
    return response.error;
  }

  return response;
};

export const sendInvite = async (organizationId, customerId) => {
  const data = {
    customerId,
  };

  const response = await post(
    `/${organizationId}/portaluser/create-and-notify-portal-applicant`,
    data,
  );

  if (response.error) {
    return response.error;
  }

  return response;
};

export const sendInviteV2 = async (
  organizationId,
  customerIds,
  mainApplicationId,
) => {
  const data = {
    customerIds,
    mainApplicationId,
  };

  const response = await post(
    `/${organizationId}/portaluser/create-and-notify-portal-applicant`,
    data,
  );

  if (response.error) {
    throw new Error(response.error.error);
  }

  return response;
};
