import { get, put } from 'utils/api';

export async function getProspectPreference({
  organizationId,
  propertyId,
  prospectId,
  options,
}) {
  return get(
    `/${organizationId}/${propertyId}/prospects/${prospectId}/prospectPreferences`,
    options,
  );
}

export async function updateProspectPreference({
  organizationId,
  propertyId,
  prospectId,
  options,
  partialProspectPreference,
}) {
  const response = await put(
    `/prospectPreferences/${organizationId}/${propertyId}/prospects/${prospectId}`,
    partialProspectPreference,
    options,
  );

  return response;
}
