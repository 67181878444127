import moment from 'moment';
import { REGEX_DATE } from 'utils/constants/regex';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_DISPLAY = 'MM/DD/YYYY';
export const DATE_FORMAT_DB = 'YYYY-MM-DD';

/**
 * Getter functions for commonly used named days (eg today, tomorrow, etc)
 */
export const getToday = () => moment().startOf('day');
export const getYesterday = () => moment().subtract(1, 'day');
export const getTomorrow = () => moment().add(1, 'day');
export const getGestationPeriod = () => moment().add(9, 'months');
export const getEighteenYears = () => moment().subtract(18, 'years');
export const getBeginningOfTime = () => moment('1901-01-01');
export const getThreeYears = () => moment().subtract(3, 'years');

/**
 * Dictionary of named day getter functions
 */
const getDateFunctions = {
  today: getToday,
  yesterday: getYesterday,
  tomorrow: getTomorrow,
  gestationPeriod: getGestationPeriod,
  eighteenYears: getEighteenYears,
};

/**
 * Function to ensure that calls to moment get passed a format if they need it.
 * @param {*} date Date can be an ISO string, `YYYY-MM-DD`, or Moment object
 */
const parseDate = (date) => {
  if (typeof date === 'string' && REGEX_DATE.test(date)) {
    return moment(date, DATE_FORMAT);
  }
  return moment(date);
};

/**
 * Validator functions. Expects that the dates have already been converted into
 * Moment objects
 */
const isSameOrAfter = (date1, date2) => date1.isSameOrAfter(date2);

const isSameOrBefore = (date1, date2) => date1.isSameOrBefore(date2);

const isValidMinorBirthday = (date) => {
  const eighteenYears = getEighteenYears();
  const gestationPeriod = getGestationPeriod();
  return date.isBetween(eighteenYears, gestationPeriod, null, '[]');
};

const isValidAdultBirthday = (date) => {
  const eighteenYears = getEighteenYears();
  const beginningOfTime = getBeginningOfTime();
  return date.isBetween(beginningOfTime, eighteenYears, null, '[]');
};

const isValidDisposedDate = (date) => {
  const today = getToday();
  const threeYears = getThreeYears();
  return date.isBetween(threeYears, today, null, '[]');
};

// atm format will be YYYY-MM-DD
const isValidDateString = (dateStr) => {
  if (typeof dateStr !== 'string') return false;

  try {
    const [year, month, day] = dateStr
      .split('-')
      .map((e) => Number.parseInt(e));

    if (
      Number.isNaN(Date.parse(dateStr)) ||
      Number.isNaN(year) ||
      Number.isNaN(month) ||
      Number.isNaN(day) ||
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31
    )
      return false;

    return true;
  } catch {
    return false;
  }
};

/**
 * Dictionary of Validator functions
 */
const validators = {
  isSameOrAfter,
  isSameOrBefore,
  isValidMinorBirthday,
  isValidAdultBirthday,
  isValidDisposedDate,
  isValidDateString,
};

/**
 * Validator function that returns a function that returns a boolean
 * @param {*} date date that is being tested
 * @param {string} type Used to lookup the validator from VALIDATORS dict above
 * Valid `type` options:
 * - isSameOrAfter
 * - isSameOrBefore
 * - isValidMinorBirthday
 * - isValidAdultBirthday
 * - isValidDisposedDate
 * - isValidDateString
 * @param {*} comparisonDate Optional date or string used for date comparisons
 */
export const dateValidator = (date, type, comparisonDate = null) => {
  if (!validators[type]) {
    return false;
  }

  if (type === 'isValidDateString') {
    return isValidDateString(date);
  }

  if (comparisonDate) {
    if (
      typeof comparisonDate === 'string' &&
      getDateFunctions[comparisonDate]
    ) {
      const dateToCompare = getDateFunctions[comparisonDate]();
      return validators[type](parseDate(date), parseDate(dateToCompare));
    }
    return validators[type](parseDate(date), parseDate(comparisonDate));
  }

  if (!comparisonDate) {
    return validators[type](parseDate(date));
  }

  return false;
};

/**
 * Uses MomentJS to format a date string to 'MM/DD/YYYY'
 * @param {string} date
 * @returns string | undefined
 */
export const dateFormatter = (dateString) =>
  !dateString ? undefined : moment.utc(dateString).format(DATE_FORMAT);

export const formatDateLocal = (dateString) =>
  !dateString ? undefined : moment(dateString).format(DATE_FORMAT);

export const formatDateDisplay = (dateString) =>
  !dateString ? undefined : moment(dateString).format(DATE_FORMAT_DISPLAY);

export const formatDateDB = (dateString) =>
  !dateString ? null : moment.utc(dateString).format(DATE_FORMAT_DB);

export const fomatWithHours = (dateString) => {
  if (!dateString) {
    return {
      date: '',
      time: '',
    };
  }

  const formattedDate = moment(dateString).format('YYYY-MM-DD');
  const formattedTime = moment(dateString).format('h:mm:ss a');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};
/**
 * TODO: Implement `dateTimeFormatter` if we have a need to track time. This
 * would not use `.utc(...)`.
 */

/**
 * We can turn this into a factory function if we want it to preform more like a
 * class. This covers current use cases, though.
 */
export default {
  dateValidator,
  dateFormatter,
};
