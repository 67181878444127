// Matches [STUDENT_TYPE_OPTIONS] in backend src/data/static/affordableEntries.js
const STUDENT_STATUS = {
  FULL_TIME_K_12: { value: 'f_tK_12', text: 'Full-Time K-12' },
  FULL_TIME_POST_K_12: { value: 'f_tPost12', text: 'Full-Time Post 12' },
  PART_TIME: {
    value: 'p_t',
    text: 'Part-Time',
  },
  NOT_A_STUDENT: {
    value: 'n_s',
    text: 'Not a student',
  },
};

export default STUDENT_STATUS;
