import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { API_URL, DATE_KEYS } from 'utils/constants';
import { formatDateDB } from 'utils/timeProvider';
import {
  destroyLocalAuth,
  getLocalAuth,
  isTokenExpired,
  isTokenOld,
  setLocalAuth,
} from './auth';

const requestAPI = async (url, data, options) => {
  let { authToken } = getLocalAuth();
  if (authToken && !authToken.startsWith('Bearer')) {
    authToken = `Bearer ${authToken}`;
  }

  const requestOptions = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    data: undefined,
  };
  if (data) {
    if (data instanceof FormData) {
      data.append('client', 'portal');

      requestOptions.data = data;
    } else {
      requestOptions.data = { ...data, client: 'portal' };

      // format dates defined in [DATE_KEYS] to DB friendly format [YYYY-MM-DD]
      if (Object.keys(requestOptions.data).includes(DATE_KEYS)) {
        Object.keys(requestOptions.data).forEach((key) => {
          if (key?.includes(DATE_KEYS) && requestOptions?.data[key]) {
            requestOptions.data[key] = formatDateDB(requestOptions.data[key]);
          }
        });
      }
    }
  }

  try {
    await checkSession();

    try {
      const response = await axios.request({
        url: `${API_URL}${url}`,
        ...requestOptions,
        ...options,
      });

      return response.data;
    } catch (error) {
      const { response, request } = error;
      const status = response?.status ?? request?.status ?? 500;
      /**
       * Bad Request / Error Response
       */
      if (response) {
        return { error: response.data, type: 'error-response', status };
      }
      /**
       * No Response
       */
      if (request) {
        return { error: request, type: 'error-request', status };
      }
      /**
       * Catch-All
       */
      return { error, type: 'error-unknown' };
    }
  } catch (error) {
    /**
     * Authentication / Invalid Token
     *   * Destroy LocalStorage
     *   * Hard Refresh destroys Contexts
     */
    console.warn(`Encountered Authentication Issue: ${error.message}`, error);
    destroyLocalAuth();
    window.location.reload();

    return true;
  }
};

const checkSession = async () => {
  const { authToken } = getLocalAuth();

  if (authToken) {
    const isExpired = isTokenExpired(authToken);
    const isOld = isTokenOld(authToken);

    if (isExpired) {
      throw Error('Authentication Token has expired');
    }

    if (isOld) {
      await extendSession(authToken);
    }
  }

  return true;
};

export const extendSession = async (authToken) => {
  const response = await axios.get(`${API_URL}/portal/auth/extendSession`, {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
  });

  setLocalAuth(response.data);
};

export const regenerateTokenIfCustomerIdMissing = async () => {
  const { authToken } = getLocalAuth();
  const decoded = jwtDecode(authToken);

  if (!decoded.customerId) {
    await extendSession(authToken);
  }
};

export const get = (url, options) =>
  requestAPI(url, null, { method: 'get', ...options });
export const post = (url, data, options) =>
  requestAPI(url, data, { method: 'post', ...options });
export const put = (url, data, options) =>
  requestAPI(url, data, { method: 'put', ...options });
export const del = (url, options) =>
  requestAPI(url, null, { method: 'delete', ...options });
